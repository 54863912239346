import Vue from 'vue'
import VueRouter from 'vue-router'
import Ping from '../components/Ping.vue'
import NotFoundComponent from '../components/NotFoundComponent.vue'
import Posts from '../components/Posts.vue'
import Weather from '../components/Weather.vue'
import Clock from '../components/Clock.vue'
import Currency from '../components/Currency.vue'
import AgentsTV from '../components/AgentsTV.vue'
import TrainingsSlider from '../components/TrainingsSlider.vue'
import Trainings from '../views/Trainings.vue'
import RealtyNews from '../views/RealtyNews.vue'
import TrainingsPreview from '../components/TrainingsPreview.vue'
import NewsPreview from '../components/NewsPreview.vue'
import CompanyNews from '../components/CompanyNews.vue'
import HolidaysPreview from '../components/HolidaysPreview.vue'
import CountdownNY from '../components/CountdownNY.vue'


Vue.use(VueRouter)

const routes = [{
    path: '/ping',
    name: 'Ping',
    component: Ping,
  },
  {
    path: '/posts',
    name: 'Posts',
    component: Posts,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '*',
    component: NotFoundComponent
  },
  {
    path: '/weather',
    component: Weather
  },
  {
    path: '/clock',
    component: Clock
  },
  {
    path: '/currency',
    component: Currency
  },
  {
    path: '/agentstv',
    component: AgentsTV
  },
  {
    path: '/trainings',
    component: Trainings
  },
  {
    path: '/realtynews',
    component: RealtyNews
  },
  {
    path: '/trainingsslider',
    component: TrainingsSlider
  },
  {
    path: '/trainingspreview/:trainingId',
    component : TrainingsPreview,
    props: true
  },
  {
    path: '/newspreview/:newsId',
    component : NewsPreview,
    props: true
  },
  {
    path: '/holidays/:newsId',
    component : HolidaysPreview,
    props: true
  },
  {
    path: '/companynews',
    component : CompanyNews,
    props: true
  },
  {
    path: '/countdownny',
    component : CountdownNY,
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
