<template>

<div class="newsslider">
  <div id="carouselExample" class="carousel slide" data-ride="carousel" data-interval="120000">
  <ol class="carousel-indicators">
    <li v-for="(item, index) in posts" data-target="#carouselExample" :data-slide-to="index" :class="{'active': index === 0}" :key="index"></li>
  </ol>
  <div class="carousel-inner">
    <!-- carousel item -->
    <div v-for="(item, index) in posts" v-bind:key="index" class="carousel-item" :class="[{'active': index === 0}, 'carousel-item']" >

      <div class="row align-items-start">

        <div class="col-lg-5 col-md col-sm pl-5 pt-5">
          <div class="row">
            <div class="col">
              <p class="heading ml-5 text-uppercase animated fadeInDown">{{ item.event_type.event_type }} <br> {{ item.event_title }}</p>
              <img class="img-fluid animated fadeInUp p-5" v-if=item.event_image v-bind:src="item.event_image.data.full_url">
              <img class="img-fluid animated fadeInUp p-5" v-else src="https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80">
            </div>
          </div>
        </div>


        <div class="col-lg-7 col-md col-sm pt-5 announce h-100 animated fadeInRight">
          <p class="event_date">{{ item.event_date | formatDate }}, {{ item.event_start_time | formatTime }}-{{ item.event_stop_time | formatTime}}<br>
          <span class="event_place">{{ item.event_place.name }}</span></p>
          <p class="event_speaker" v-if="item.event_speaker">{{ item.event_speaker }}</p>
          <p class="event_price" v-if="item.event_price">Стоимость — {{ item.event_price }}₽</p>
          <br>
          <p class="event_comments" style="white-space: pre-line;">{{ item.event_comments }}</p>
        </div>

      </div>

      </div>
    </div>
      <a class="carousel-control-prev" href="#carouselExample" role="button" data-slide="prev">
        <span class="" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExample" role="button" data-slide="next">
        <span class="" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
  </div>

</div>





</template>

<style>
.event_date {
  font-size: 34px;
}

.event_place {
  font-size: 34px;
}

.event_speaker {
  font-size: 34px;
}

.event_price {
  font-size: 34px;
}

.event_comments {
  line-height: 1.3;
  font-size: 34px;
  font-weight: medium;
  width: 85%;
  animation-duration: 1s;
}
</style>

<script>
import axios from 'axios'
import moment from 'moment'

export default {

  props: {
  trainingId: {
    default: '0',
    type: String
  }
},
  data() {
    return {
      posts: [],
    };
  },

  methods: {

    SetApiPath() {
      if (this.trainingId != 0) {
          this.api_path = `https://weather.matveymatvey.com:8001/post2tv/items/trainings/${this.trainingId}?fields=*.*`
          axios
            .get(this.api_path)
            .then(response => (this.$set(this.posts, 0, response.data.data)))
            .catch(err => {this.errors.push(err)})
      }
      if (this.trainingId == 0) {
          this.api_path = 'https://weather.matveymatvey.com:8001/post2tv/items/trainings?status=published&fields=*.*';
          axios
            .get(this.api_path)
            .then(response => (this.posts = response.data.data))
            .catch(err => {this.errors.push(err)})
      }
    },
  FetchPost() {
    this.SetApiPath();
    axios
      .get(this.api_path)
      .then(response => (this.$set(this.posts, 0, response.data.data)))
      .catch(err => {this.errors.push(err)})
      },

    },
    created() {
      this.FetchPost();
    },

  filters: {
    formatDate: function(value) {
    if (value) {
      return moment(String(value)).format('dddd, DD MMMM ');
      }
    },

    formatTime: function(value) {
    if (value) {
      return moment(String(value), "HH:mm:ss").format('HH:mm');
      }
  }
}}

</script>
