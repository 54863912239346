<template>    
  <div class="weather" v-if="weather.temp">
      <div class="weather__temp mr-3">{{ weather.temp }}°C</div>      
      <div class="weather__detail">
        <img class="weather__icon" :src="weather.icon">
        {{ this.weather.detailed }}
        <div class="weather__updated">обновлено в {{ weather.updated }}</div>
      </div>
  </div>
</template>

<style scoped>
.weather__temp {
  display: inline-block;
}

.weather__detail {
  display: inline-block;
}
.weather {
  font-size: 32px;
  font-weight: 400;
}

.weather__detail {
  position: relative;
}

.weather__updated {
  top:-10;
  right: 0;
  position: absolute;
  font-size: 12px;
  font-weight: 100;
}

.weather__icon {
    /* background-color: #ffffff; */
    border-radius: 50%;
    margin-right: -15px;
}
</style>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      weather: [],
    };
  },

  methods: {

    getWeather() {
      const path = 'https://weather.matveymatvey.com/getweather';
      axios.get(path)
        .then(response => (this.weather = response.data))
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.getWeather();
  },

};
</script>
