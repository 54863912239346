<template>
  <div class="realtyNews">
    <div v-if="(this.posts.length > 0)" class="animated fadeInDown holidaybar navbar"></div>
    
    <div v-if="this.posts == 0" style="z-index: -800;">
      <img class="img-fluid w-100 animated" src="../assets/spb.jpg">
    </div>
    <div
      v-else
      id="realtyNewsCarousel"
      class="carousel slide animated fadeIn"
      data-ride="carousel"
      data-interval="120000"
      data-pause="false"
      >
      <ol class="carousel-indicators" v-if="showIndicators">
        <li
          v-for="(item, index) in posts"
          data-target="#realtyNewsCarousel"
          :data-slide-to="index"
          :class="{ active: index === 0 }"
          :key="index"
        ></li>
      </ol>
      <div class="row">
        <div class="col pl-5">
          <h1 class="display-4 pt-3">Новости недвижимости от BN.RU</h1>
        </div>
      </div>
      <div class="carousel-inner">
        <div
          v-for="(item, index) in posts"
          v-bind:key="item.id"
          :class="[{ active: index === 0 }, 'carousel-item']"
        >
          

          <div class="row align-items-start">
            <div class="col-lg-5 col-md col-sm pt-5 pl-5">
              <div class="row">
                <div class="col">
                  <img
                    class="img-fluid mh-100 pr-5 post-img mx-auto d-block animated fadeInUp"
                    v-if="item.image"
                    v-bind:src="item.image"
                  />
                  <img
                    class="img-fluid"
                    v-else
                    src="https://images.unsplash.com/photo-1495020689067-958852a7765e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md col-sm pt-5 announce mh-100">
              <h1 class="pb-3 heading event_title animated fadeInDown">{{ item.title }}</h1>
              <p class="event_comments animated fadeInRight" style="white-space: pre-line">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#realtyNewsCarousel"
        role="button"
        data-slide="prev"
      >
        <span class="" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#realtyNewsCarousel"
        role="button"
        data-slide="next"
      >
        <span class="" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    
  </div>
</template>

<style>

</style>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      posts: [],
      showIndicators: false,
    };
  },

  methods: {
    FetchPosts() {
      axios
        .get("https://weather.matveymatvey.com/download_feed")
        .then((response) => (this.posts = response.data))
        .catch((err) => {
          this.errors.push(err);
        });
    },
  },
  created() {
    setTimeout(this.FetchPosts, 4000)
  },

  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("dddd, DD MMMM ");
      }
    },

    formatTime: function (value) {
      if (value) {
        return moment(String(value), "HH:mm:ss").format("HH:mm");
      }
    },
  },
};
</script>
