<template>
  <div class="trainingSlider">
    <div
      id="carouselExample"
      class="carousel slide"
      data-ride="carousel"
      data-interval="120000"
    >
      <ol class="carousel-indicators" 
          v-if="carouselindicators"
      >
        <li
          v-for="(item, index) in posts"
          data-target="#carouselExample"
          :data-slide-to="index"
          :class="{ active: index === 0 }"
          :key="index"
        ></li>
      </ol>
      <div class="carousel-inner">
        <div
          v-for="(item, index) in posts"
          v-bind:key="item.id"
          :class="[{ active: index === 0 }, 'carousel-item']"
        >
          <div class="row align-items-start">
            <div class="col-lg-5 col-md col-sm pt-5 pl-5">
              <div class="row">
                <div class="col">
                  <h1 class="heading event_title animated fadeInDown">{{ item.name }}</h1>
                  <img
                    class="img-fluid mh-100 pt-5 pb-5 pr-5 post-img d-block animated fadeInUp"
                    v-if="item.image"
                    v-bind:src="item.image"
                  />
                  <img
                    class="img-fluid animated fadeInUp"
                    v-else
                    src="https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-7 col-md col-sm pt-5 announce mh-100 animated fadeInRight">
              <div class="event_date">
                <i class="mr-3 fa fa-calendar"></i>{{ item["date-as-string"] }}
              </div>

              <div class="event_place" v-if="item.place">
                <i class="mr-3 fa fa-map-marker-alt"></i>{{ item.place }}
              </div>
              <p class="event_speaker" v-if="item.presenter">
                <i class="mr-3 fa fa-user"></i>{{ item.presenter }}
              </p>

              <p class="event_price" v-if="item.price">
                Стоимость - {{ item.price }}₽
              </p>
              <br />
              <p class="event_comments" style="white-space: pre-line">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExample"
        role="button"
        data-slide="prev"
      >
        <span class="" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExample"
        role="button"
        data-slide="next"
      >
        <span class="" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      carouselindicators: false,
      posts: [],
    };
  },

  methods: {
    FetchPosts() {
      axios
        .get("https://api.advecs.org/v2/training/all")
        .then((response) => (this.posts = response.data.items))
        .catch((err) => {
          this.errors.push(err);
        });
    },
  },
  created() {
    this.FetchPosts();
  },

  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("dddd, DD MMMM ");
      }
    },

    formatTime: function (value) {
      if (value) {
        return moment(String(value), "HH:mm:ss").format("HH:mm");
      }
    },
  },
};
</script>
