<template>
<div class="newsslider">


  <div id="carouselExample" class="carousel slide caption-animate" data-ride="carousel" data-interval="4200">
  <ol class="carousel-indicators">
    <li v-for="(item, index) in posts" data-target="#carouselExample" :data-slide-to="index" :class="{'active': index === 0}" :key="index"></li>
  </ol>
  <div class="carousel-inner">
    <div v-for="(item, index) in posts" v-bind:key="item.id" :class="[{'active': index === 0}, 'carousel-item']" >

      <div class="row align-items-start">

        <div class="col-lg-5 col-md col-sm pl-5 pt-5">
          <div class="row">
            <div class="col">
              <p class="heading ml-5 text-uppercase animated fadeInDown">{{ item.title }}</p>
              <img class="img-fluid animated fadeInUp p-5" v-if=item.news_image v-bind:src="item.news_image.data.full_url">
              <img class="img-fluid" v-else src="../assets/news.png">
            </div>
          </div>
        </div>


        <div class="col-lg-7 col-md col-sm pt-5 announce h-100">
          <p class="news_body animated fadeInRight" style="white-space: pre-line">{{ item.body }}</p>
        </div>

      </div>

      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExample" role="button" data-slide="prev">
  <span class="" aria-hidden="true"></span>
  <span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="#carouselExample" role="button" data-slide="next">
  <span class="" aria-hidden="true"></span>
  <span class="sr-only">Next</span>
</a>
  </div>
</div>




</template>

<style>
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Exo+2&display=swap');




</style>

<script>
import axios from 'axios'
import moment from 'moment'

export default {

  props: {
  newsId: {
    default: '0',
    type: String
  }
},
  data() {
    return {
      posts: [],
      api_path: ''
    };
  },

  methods: {
    SetApiPath() {
      if (this.newsId != 0) {
          this.api_path = `https://weather.matveymatvey.com:8001/post2tv/items/news/${this.newsId}?fields=*.*`
          axios
            .get(this.api_path)
            .then(response => (this.$set(this.posts, 0, response.data.data)))
            .catch(err => {this.errors.push(err)})
      }
      if (this.newsId == 0) {
          this.api_path = 'https://weather.matveymatvey.com:8001/post2tv/items/news?status=published&fields=*.*';
          axios
            .get(this.api_path)
            .then(response => (this.posts = response.data.data))
            .catch(err => {this.errors.push(err)})
      }
    },
  FetchPost() {
    this.SetApiPath();
    axios
      .get(this.api_path)
      .then(response => (this.$set(this.posts, 0, response.data.data)))
      .catch(err => {this.errors.push(err)})
      },

    },
    created() {
      this.SetApiPath();
    },

  filters: {
    formatDate: function(value) {
    if (value) {
      return moment(String(value)).format('dddd, DD MMMM ');
      }
    },

    formatTime: function(value) {
    if (value) {
      return moment(String(value), "HH:mm:ss").format('HH:mm');
      }
  }
}}

</script>
