<template>
<div class="container">

  <b-modal ref="addPostModal" id="post-modal" title="Добавить новую запись" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset" class="w-100">
      <b-form-group id="form-datestart-group" label="Начало трансляции:" label-for="form-datestart-input">
        <b-form-input id="form-datestart-input" type="date" valuev-model="addPostForm.datestart">
        </b-form-input>
      </b-form-group>

      <b-form-group id="form-dateend-group" label="Окончание трансляции:" label-for="form-datestart-input">
        <b-form-input id="form-dateend-input" type="date" v-model="addPostForm.dateend">
        </b-form-input>
      </b-form-group>

      <b-form-group id="form-title-group" label="Заголовок:" label-for="form-title-input">
        <b-form-input id="form-title-input" type="text" v-model="addPostForm.title" required placeholder="Введите заголовок">
        </b-form-input>
      </b-form-group>

      <b-form-group id="form-description-group" label="Текст:" label-for="form-description-input">
        <b-form-textarea id="form-description-input" rows="3" max-rows="6" v-model="addPostForm.description" required placeholder="Введите текст">
        </b-form-textarea>
      </b-form-group>

      <b-form-group id="form-file-group" label="Загрузить картинку" label-for="form-file-input">
        <b-form-file id="form-file-input" placeholder="Выберите файл для загрузки (только картинки)" type="file" v-model="addPostForm.File">
        </b-form-file>
        <div class="text-muted" mt-2 v-if="addPostForm.File">Выбрано - {{ this.addPostForm.File.name }}</div>
      </b-form-group>


      <b-form-group>
        <b-form-checkbox id="form-hidden-input" v-model="addPostForm.hidden" name="checkbox-1" value="true" unchecked-value="false">
          Скрыт
        </b-form-checkbox>
      </b-form-group>

      <div class="btn-group" role="group">
        <b-button type="submit" variant="primary" class="btn btn-sm">Добавить</b-button>
        <b-button type="reset" variant="" class="btn btn-sm">Отменить</b-button>
      </div>


    </b-form>
  </b-modal>


  <b-modal ref="editPostModal" id="post-update-modal" title="Обновить запись" hide-footer>
    <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-100">
      <b-form-group id="form-datestart-group" label="Начало трансляции:" label-for="form-datestart-input">
        {{this.editPostForm.StartDate}}
        <b-form-input id="form-datestart-input" type="date" v-model="editPostForm.StartDate">
        </b-form-input>
      </b-form-group>

      <b-form-group id="form-dateend-group" label="Окончание трансляции:" label-for="form-datestart-input">
        <b-form-input id="form-dateend-input" type="date" v-model="editPostForm.EndDate">
        </b-form-input>
      </b-form-group>

      <b-form-group id="form-title-group" label="Заголовок:" label-for="form-title-input">
        <b-form-input id="form-title-input" type="text" v-model="editPostForm.Title" required placeholder="Введите заголовок">
        </b-form-input>
      </b-form-group>

      <b-form-group id="form-description-group" label="Текст:" label-for="form-description-input">
        <b-form-textarea id="form-description-input" rows="3" max-rows="6" v-model="editPostForm.Description" required placeholder="Введите текст">
        </b-form-textarea>
      </b-form-group>


    <b-form-group label="Загруженные картинки">
      <a class="" v-for="(picture, index) in editPostForm.Attachment" :key="index" v-bind:href="'http://matveymatvey.com:1337' + picture.url"><img class="img-thumbnail w-25" v-bind:src="'http://matveymatvey.com:1337' + picture.url" /></a>
    </b-form-group>

      <b-form-group id="form-file-group" label="Загрузить картинку" label-for="form-file-input">
        <b-form-file id="form-file-input" placeholder="Выберите файл для загрузки (только картинки)" type="file" v-model="editPostForm.File">
        </b-form-file>
        <div class="text-muted" mt-2 v-if="editPostForm.File">Выбрано - {{ this.editPostForm.File.name }}</div>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox id="form-hidden-input" v-model="editPostForm.Hidden" class="" name="checkbox-1">
          Скрыт
          <span class="text-muted" v-if="editPostForm.Hidden">Запись не будет отображаться в трансляции</span>
          <span class="text-muted text-small" v-else></span>


        </b-form-checkbox>
      </b-form-group>

      <div class="btn-group" role="group">

        <b-button type="submit" variant="primary" class="btn btn-sm">Обновить</b-button>
        <b-button type="reset" variant="" class="btn btn-sm">Отменить</b-button>
      </div>
    </b-form>
  </b-modal>


  <div class="row">
    <div class="col">
      <h1>Новости на ADVECS-TV</h1>
      <br><br><br>
      <alert :message=message v-if="showMessage"></alert>
      <p class="lead">Всего записей - {{ posts.length }}</p>

      <b-btn type="button" class="float-left btn btn-success btn-sm m-1" v-b-modal.post-modal>Добавить запись</b-btn>
      <b-btn type="button" class="float-left btn btn-sm m-1" @click="getPosts">Обновить список записей</b-btn>

      <br><br>
      <table class="table table-hover" id="postsTable">
        <thead>
          <tr>
            <th scope="col">id</th>
            <th scope="col">Заголовок</th>
            <th scope="col">Описание</th>
            <th scope="col">Начало трансляции</th>
            <th scope="col">Окончание трансляции</th>
            <th scope="col"></th>
            <th scope="col"></th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(post, index) in posts" :key="index">
            <td>{{ post.id }}</td>
            <td>{{ post.Title }}</td>
            <td>{{ post.Description }}</td>

            <td>{{ post.StartDate }}</td>
            <td>{{ post.EndDate }}</td>
            <td>
                <a class="" v-for="(picture, index) in post.Attachment" :key="index" v-bind:href="'http://matveymatvey.com:1337' + picture.url"><img class="img-thumbnail w-25" v-bind:src="'http://matveymatvey.com:1337' + picture.url" /></a>
            </td>
            <td>
              <span class="text-muted text-small" v-if="post.Hidden">Скрыт</span>

            </td>


            <td>
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-secondary btn-sm" @click="editPost(post)">
                  Обновить
                </button>
                <button type="button" class="btn btn-danger btn-sm" @click="onDeletePost(post)">
                  Удалить
                </button>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import Alert from './Alert.vue'


export default {
  data() {
    return {
      addPostForm: {
        title: '',
        description: '',
        hidden: false,
        StartDate: '',
        EndDate: '',
        File: null,
      },

      editPostForm: {
        id: '',
        Title: '',
        Description: '',
        Hidden: false,
        StartDate: '',
        EndDate: '',
        File: null,
        Uploads: ''
      },

      message: '',
      showMessage: false,
      posts: [],
    };
  },
  components: {
    alert: Alert,
  },
  methods: {
    initForm() {
      this.addPostForm.title = ''
      this.addPostForm.description = ''
      this.addPostForm.hidden = false
      this.addPostForm.StartDate = ''
      this.addPostForm.EndDate = ''
      this.addPostForm.File = null
      this.editPostForm.id = ''
      this.editPostForm.Title = ''
      this.editPostForm.Description = ''
      this.editPostForm.Hidden = false
      this.editPostForm.StartDate = ''
      this.editPostForm.EndDate = ''
      this.editPostForm.File = null
      this.editPostForm.Uploads = ''
    },

    onSubmit(event) {
      var payload = new FormData()
      event.preventDefault()
      this.$refs.addPostModal.hide()
      let is_hidden = false;
      if (this.addPostForm.hidden) is_hidden = true;

      const fields = {
        Title: this.addPostForm.title,
        Description: this.addPostForm.description,
        Hidden: is_hidden,
        StartDate: this.addPostForm.datestart,
        EndDate: this.addPostForm.dateend,
      };

      payload.append('data', JSON.stringify(fields));
      if (this.addPostForm.File) payload.append(`files.Attachment`, this.addPostForm.File, this.addPostForm.File.name);

      this.addPost(payload)
      this.initForm();

    },

    addPost(payload) {
      const path = 'http://matveymatvey.com:1337/posts';
      axios.post(path, payload)
        .then(() => {
          this.getPosts()
          this.message = 'Запись добавлена'
          this.showMessage = true;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error, payload);
          this.getPosts();
        });
    },

    editPost(post) {
      this.editPostForm = post
      this.message = post
      this.showMessage = true
      this.$refs.editPostModal.show();
    },

    onSubmitUpdate(event) {
      var payload = new FormData()
      event.preventDefault()
      this.$refs.editPostModal.hide()
      let is_hidden = false
      if (this.editPostForm.Hidden === true) {
        is_hidden = true
      } else {
        is_hidden = false
      }
      is_hidden = this.editPostForm.Hidden
      let picturelist = []
      let picture = ''

      for (picture in this.editPostForm.Files) picturelist.append(picture.url)

      const fields = {
        Title: this.editPostForm.Title,
        Description: this.editPostForm.Description,
        Hidden: is_hidden,
        StartDate: this.editPostForm.StartDate,
        EndDate: this.editPostForm.EndDate,
        Uploads: picturelist
      }

      payload.append('data', JSON.stringify(fields));
      if (this.editPostForm.File) payload.append(`files.Attachment`, this.editPostForm.File, this.editPostForm.File.name);
      this.updatePost(payload, this.editPostForm.id)

    },

    updatePost(payload, postID) {
      const headers = {
        'Content-Type': 'multipart/form-data'
      }
      const path = `http://matveymatvey.com:1337/posts/${postID}`;
      axios.put(path, payload, headers)
        .then(() => {
          this.getPosts();
          this.message = 'Запись обновлена';
          this.showMessage = true;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          this.getPosts();
        });
    },

    removePost(postID) {
      const path = `http://matveymatvey.com:1337/posts/${postID}`;
      axios.delete(path)
        .then(() => {
          this.getPosts();
          this.message = 'Запись ' + postID + ' удалена';
          this.showMessage = true;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          this.getPosts();
        });
    },

    onDeletePost(post) {
      this.removePost(post.id);
    },

    onReset(evt) {
      evt.preventDefault();
      this.$refs.addPostModal.hide();
      this.initForm();
    },


    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editPostModal.hide();
      // this.initForm();
    },

    getPosts() {
      const path = 'http://matveymatvey.com:1337/posts?_sort=id:DESC';
      axios.get(path)
        .then(response => (this.posts = response.data))
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.getPosts();
  },

};
</script>
