<template>
  <div class="clock d-flex align-items-center">
    <div class="time d-flex-inline pr-5">{{ currentTime }}</div>
    <div class="date d-flex-inline">{{$moment().format("dddd D MMM YYYY")}}</div>
  </div>
</template>

<style>
.clock {
  font-size: 32px;
  font-weight: 400;
}
</style>

<script>

export default {
  data() {
    return {
      currentTime: null
    };
  },

  methods: {
    updateCurrentTime() {
      this.currentTime = this.$moment().format('HH:mm:ss');
    }

  },
  created() {
    this.currentTime = this.$moment().format('HH:mm:ss');
    setInterval(() => this.updateCurrentTime(), 1 * 1000);
  },
};

</script>
