<template>
  <div class="newsslider">
    <div
      id="carouselExample"
      class="pt-5 carousel slide caption-animate"
      data-ride="carousel"
      v-if="posts"
      data-interval="120000"
    >
      <ol class="carousel-indicators" v-if="showIndicators">
        <li
          v-for="(item, index) in posts.length + trainings.length"
          data-target="#carouselExample"
          :data-slide-to="index"
          :class="{ active: index === 0 }"
          :key="index"
        ></li>
      </ol>
      <div class="carousel-inner">
        <!-- NEWS CAROUSEL -->
        <div
          v-for="(item, index) in posts"
          v-bind:key="item.id"
          :class="[{ active: index === 0 }, 'carousel-item']"
        >
          <div class="row align-items-start">
            <div class="col col-md col-sm">
              <p
                v-if="item.title"
                class="pl-5 pb-5 heading text-uppercase animated fadeInDown"
              >
                {{ item.title }}
              </p>
              <img
                class="img-fluid animated px-5 fadeInDown d-block mx-auto"
                v-if="item.news_image"
                v-bind:src="item.news_image.data.full_url"
                :class="getImgClass(item.body)"
              />
              <img
                class="img-fluid animated fadeInUp d-block mx-auto p-5"
                v-else
                src="../assets/news.png"
              />
            </div>

            <div v-if="item.body" class="col-lg-7 col-md col-sm announce h-100">
              <p
                class="news_body ml-5 animated fadeInRight"
                style="white-space: pre-line"
              >
                {{ item.body }}
              </p>
            </div>
          </div>
        </div>

        <!-- TRAININGS CAROUSEL -->
        <div
          v-for="(item, index) in trainings"
          v-bind:key="index"
          class="carousel-item"
        >
          <div class="row align-items-start">
            <div class="col-lg-5 col-md col-sm pl-5 pt-5">
              <p class="heading ml-5 text-uppercase animated fadeInDown">
                {{ item.name }}
              </p>
              <img
                class="img-fluid animated fadeInUp p-5"
                v-if="item.image"
                v-bind:src="item.image"
              />
              <img
                class="img-fluid animated fadeInUp p-5"
                v-else
                src="https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
              />
            </div>

            <div
              class="col-lg-7 col-md col-sm pt-5 announce h-100 animated fadeInRight"
            >
              <p class="event_date">
                {{ item['date-as-string'] }}<br />
                <span class="event_place">{{ item.place }}</span>
              </p>
              <p class="event_speaker" v-if="item.presenter">
                {{ item.presenter }}
              </p>
              <p class="event_price" v-if="item.price">
                Стоимость — {{ item.price }}₽
              </p>
              <br />
              <p class="event_description" style="white-space: pre-line">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExample"
        role="button"
        data-slide="prev"
      >
        <span class="" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExample"
        role="button"
        data-slide="next"
      >
        <span class="" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div v-else class="hero">
      <img class="mx-auto" src="https://images.unsplash.com/photo-1610197361600-33a3a5073cad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80">
    </div>
  </div>
</template>

<style>
.announce {
  font-weight: 700;
}

.heading {
  font-weight: 900;
  font-size: 46px;
  letter-spacing: 3px;
  line-height: 1.2;
}

.news_body {
  /* line-height: 1.3; */
  font-size: 34px;
  font-weight: 500;
  width: 85%;
}

.singleImg {
  max-height: 70vh;
}
</style>

<script>
import axios from "axios";
import moment from "moment";

export default {
  props: {
    newsId: {
      default: "0",
      type: String,
    },
  },
  data() {
    return {
      showIndicators: false,
      posts_count: "",
      posts: "",
      trainings: "",
      api_path_news:
        "https://weather.matveymatvey.com:8001/post2tv/items/news?status=published&sort=?&fields=*.*",
      api_path_trainings:
        "https://api.advecs.org/v2/training/all",
    };
  },

  methods: {
    FetchNews() {
      axios
        .get(this.api_path_news)
        .then((response) => (this.posts = response.data.data))
        .catch((err) => {
          this.errors.push(err);
        });
    },
    FetchTrainings() {
      axios
        .get(this.api_path_trainings)
        .then(response => (this.trainings = response.data.items))
        .catch(err => {this.errors.push(err)})
    },
    FetchAll() {
      this.FetchNews();
    },
    CountPosts() {
      this.posts_count = this.posts.length + this.trainings.length;
    },
    getImgClass(body) {
      let imgClass = ''
      body ? imgClass = "" : imgClass =  "singleImg"
      return imgClass
    }
  },
  created() {
    this.FetchAll();
    this.CountPosts();
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("dddd, DD MMMM ");
      }
    },
    formatTime: function (value) {
      if (value) {
        return moment(String(value), "HH:mm:ss").format("HH:mm");
      }
    },
  },
};
</script>
