<template>
  <div class="container-fluid pl-0 pr-0 main">
    <NavBar></NavBar>
    <div class="holidaybar navbar animated fadeInDown"></div>
    <CompanyNews></CompanyNews>
    <div class="fixed-bottom bgDiv">
      <img class="bgImg float-right" src="../assets/bg.png">
    </div>
    <Footer></Footer>
  </div>
</template>

<style>

.footer {
  height: 40px;
  font-size: 24px;
}

.footer svg {
  vertical-align: middle;
}

.bgImg {
  margin-top: 110px;
}

</style>

<script>
import NavBar from './NavBar.vue';
import CompanyNews from './CompanyNews.vue'
import Footer from './Footer.vue';


export default {
components: {
  Footer,
  NavBar,
  CompanyNews,
  }
}
</script>
