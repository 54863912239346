<template>
  <div class="container-fluid">
    <h1 class="heading">You are watching<br>ADVECS-TV</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
};
</script>
