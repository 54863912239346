<template>
  <div class="container-fluid pl-0 pr-0 main">
    <NavBar></NavBar>
    <RealtyNewsSlider></RealtyNewsSlider>
     <div class="fixed-bottom bgDiv">
      <img class="bgImg float-right" src="../assets/bg.png">
    </div>
    <Footer></Footer>
  </div>
</template>

<style>

.footer {
  min-height: 40px;
  font-size: 24px;
}

.footer svg {
  vertical-align: middle;
}


</style>

<script>
import NavBar from '../components/NavBar.vue';
import Footer from '../components/Footer.vue';
import RealtyNewsSlider from '../components/RealtyNewsSlider.vue'
 

export default {
components: {
  NavBar,
  Footer,
  RealtyNewsSlider
  }
}
</script>
