import 'bootstrap/dist/css/bootstrap.css'
import 'jquery/dist/jquery.js'
import 'popper.js/dist/popper.js'
import 'bootstrap/dist/js/bootstrap.js'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'animate.css/animate.css'

import moment from 'moment-timezone'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueMoment from 'vue-moment'


moment.locale('ru');

Vue.config.productionTip = false
Vue.use(VueMoment, {
    moment,
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
