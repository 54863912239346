<template>
  <nav class="text-monospace navbar navbar-dark bg-dark text-light px-2 py-1">
    <div class="d-flex justify-content-start">
      <a class="navbar-brand" href="#">
        <img
          src="../assets/Logo_advecs_mnh_wht.png"
          class="animated fadeIn ml-3 my-2"
          height="60px"
          alt="advecs"
        />
      </a>
      <Clock class="px-3 animated fadeIn align-middle"></Clock>
    </div>

    <div class="d-flex justify-content-end">
      <Currency class="px-4 animated fadeIn"></Currency>
      <Weather class="px-4 animated fadeIn"></Weather>
    </div>
  </nav>
</template>

<style>
.weather {
  font-size: 32px;
  font-weight: 400;
}
.detail {
  line-height: 32px;
}
.updated {
  font-size: 12px;
  font-weight: 100;
}
.weather_icon {
  /* background-color: #ffffff; */
  border-radius: 50%;
}
</style>

<script>
import Weather from "./Weather.vue";
import Clock from "./Clock.vue";
import Currency from "./Currency.vue";

export default {
  components: {
    Weather,
    Clock,
    Currency,
  },
  data() {
    return {};
  },

  methods: {},
  created() {},
};
</script>
