<template>
  <div class="currency align-items-center d-flex" v-if="rates.usd">
    ${{ rates.usd.Value.slice(0,5).replace(/,/g, '.') }} €{{ rates.eur.Value.slice(0,5).replace(/,/g, '.') }}
  </div>
</template>

<style scoped>
.currency {
  font-weight: 400;
  font-size: 32px;
}
</style>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      rates: []
    };
  },

  methods: {
    getCurrency() {
      axios.get('https://weather.matveymatvey.com/download_rates')
        .then(response => (this.rates = response.data))
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },

  mounted() {
    this.getCurrency();
  },
};
</script>
