<template>
  <div class="container-fluid p-0 m-0">
    <div class="overlay"></div>

    <video autoplay muted loop id="myVideo" hwz="z-index:-1; on;">
        <source type="video/webm" v-bind:src="`${publicPath}${selectedVideo}`">
    </video>
    <div class="container-fluid animated fadeIn h-100 content-container overflow-auto d-inline-block " style="padding-top:230px">
      <h1 class="h1 text-center">До Нового Года осталось</h1>


      <div class="flex-container-time">
        <div class="flex-item">
          <p class="text" id="days">{{days}}</p>
        </div>
        <div class="flex-item">
          <p class="text" id="hours">{{hours}}</p>
        </div>
        <div class="flex-item">
          <p class="text" id="minutes">{{minutes}}</p>
        </div>
        <div class="flex-item">
          <p class="text" id="seconds">{{seconds}}</p>
        </div>
      </div>

      
      <!-- Flex container to display string data begin -->
      <div class="flex-container-data">
        <div class="flex-caption">
          <p class="text" id="daysName">ДНЕЙ</p>
        </div>
        <div class="flex-caption">
          <p class="text" id="hoursName">ЧАСОВ</p>
        </div>
        <div class="flex-caption">
          <p class="text" id="minutesName">МИНУТ</p>
        </div>
        <div class="flex-caption">
          <p class="text" id="secondsName">СЕКУНД</p>
        </div>
      </div><!-- End Flex container to display string data -->





    </div>
    </div>

</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

body {}

body .overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

h1 {
  margin-top: 50px;
  font-size: 112px;
  margin-bottom: 30px;
  font-family: 'Bad Script', cursive;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  object-fit: fill;
}

.content-container {
  z-index: 2;
  position: relative;
  color: white;
}

/* captions */
.flex-container-data, .flex-container-time {
  display: flex;
  display: -ms-flexbox; /* IE 10 */
  flex-wrap: nowrap;
  -ms-flex-wrap: wrap; /* IE 10 */
  font-family: 'Lato', sans-serif;
  justify-content: center;
}

/* time */
.flex-container-time > .flex-item {
  width: 15%;
  /* height: 100%; */
  /* margin: 0 10px; */
  text-align: center;
  font-size: 125px;
  font-family: 'Lato', sans-serif;
  margin-top: -50px;


}

.flex-container-data > .flex-caption {
  width: 15%;
  /* height: 40%;  */
  text-align: center;
  font-size: 32px;
  font-weight: lighter;

}


</style>

<script>
export default {
  data: function () {
  return {
    publicPath: process.env.BASE_URL,
    videos: [
      'videos/snow1.mp4',
      'videos/snow2.mp4',
      'videos/snow3.mp4',
    ],
    selectedVideo: null,
    eventTime: this.$moment("Jan 1, 2024 00:00:00"),
    currentTime: null,
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  }
},
methods: {
  randomItem (items) {
    return items[Math.floor(Math.random()*items.length)];
  },
  countdown() {
    this.currentTime = this.$moment()
    let diffTime = this.eventTime - this.currentTime;
    let duration = this.$moment.duration(diffTime*1000, 'milliseconds');
    duration = this.$moment.duration(this.eventTime.diff(this.currentTime))

    this.days = duration.days(),
    this.hours = duration.hours(),
    this.minutes = duration.minutes(),
    this.seconds = duration.seconds()
  }
},
created() {
  this.selectedVideo = this.randomItem(this.videos)
  setInterval(() => this.countdown(), 1 * 1000);
}
}


</script>

