<template>
   <div class="fixed-bottom footer bg-dark text-light justify-content-center text-center">
      <span class="px-5 animated fadeIn"><i class="fab fa-youtube"></i>&nbsp;&nbsp;<b>advecsspb</b></span>
      <span class="px-5 animated fadeIn"><i class="fab fa-vk"></i>&nbsp;&nbsp;<b>advecs</b></span>
      <span class="px-5 animated fadeIn"><i class="fab fa-telegram"></i>&nbsp;&nbsp;<b>advecs</b></span>
    </div>
</template>

<style>

</style>

<script>
export default {
  components: {

  },
  data() {
    return {};
  },

  methods: {},
  created() {},
};
</script>
