<template>
  <div class="container-fluid pl-0 pr-0 main">
    <nav class="text-monospace navbar navbar-dark bg-dark text-light px-2 py-1 align-items-middle">
      <div class="d-flex justify-content-start">
        <a class="navbar-brand" href="#">
          <img src="../assets/Logo_advecs_mnh_wht.png" class="animated fadeIn  ml-3 my-2" height="60px" alt="advecs">
        </a>
      <Clock class="px-3 animated fadeIn align-middle"></Clock>
      <!-- OMG -->
      </div>

      <div class="d-flex justify-content-end">
        <Currency class="px-4 animated fadeIn"></Currency>
        <Weather class="px-4 animated fadeIn"></Weather>
      </div>
    </nav>
    <div class="holidaybar navbar"></div>
    <TrainingsSlider></TrainingsSlider>
    <div class="fixed-bottom bgDiv">
      <img class="bgImg float-right" src="../assets/bg.png">
    </div>
    <div class="fixed-bottom footer bg-dark text-light justify-content-center text-center">
      <span class="px-5 animated fadeIn"><i class="fab fa-youtube"></i>&nbsp;&nbsp;<b>advecsspb</b></span>
      <!-- <span class="px-5 animated fadeIn"><i class="fab fa-instagram"></i>&nbsp;&nbsp;<b>advecs</b></span> -->
      <span class="px-5 animated fadeIn"><i class="fab fa-vk"></i>&nbsp;&nbsp;<b>advecs</b></span>
      <span class="px-5 animated fadeIn"><i class="fab fa-telegram"></i>&nbsp;&nbsp;<b>advecs</b></span>
    </div>
  </div>
</template>

<style>
.bgDiv {
  z-index: -19999;
  margin-bottom: -10px;
}

.bgImg {
  width: 800px;
  opacity: 0.33;
}

.footer {
  height: 40px;
  font-size: 24px;
}

.footer svg {
  vertical-align: middle;
}


</style>

<script>
import Weather from '../components/Weather.vue'
import Clock from '../components/Clock.vue'
import Currency from '../components/Currency.vue'
import TrainingsSlider from '../components/TrainingsSlider.vue'


export default {
components: {
  Weather,
  Clock,
  Currency,
  TrainingsSlider
  }
}
</script>
